import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import MDSLogo from "@/images/logo.svg";
import { BsTwitter } from "react-icons/bs";
import { GrLinkedinOption } from "react-icons/gr";
import { FaRedditAlien } from "react-icons/fa";
import { useRouter } from "next/router";

const frame = `<iframe style="height : calc(100vh - 150px); max-height: 1000px;" class="airtable-embed" frameborder="0" onmousewheel="" src="https://airtable.com/embed/shrAyrKkjBFARJaRS?backgroundColor=orange"  width="100%" height="100%"  ></iframe>`;

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe || "" }}
    />
  );
}

export const ModalContactUs = (props) => {
  return (
    <Modal {...props} >
      <Modal.Header className="border-0" closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className="m-0 p-0 ">
        <Iframe iframe={frame} />
      </Modal.Body>
    </Modal>
  );
}

const Footer = (props) => {
  const router = useRouter();
  const { pathname } = router;
  const [modalShow, setModalShow] = useState(false);
  const handleHideModal = () => {
    setModalShow(false);
  }


  const trackClick = (event) => {
    window.analytics.track(`footer.social_media_link.${event}_clicked`)
  };


  return (
    <footer className="mds-footer mds-bg-primary-muted py-5 mt-auto">
      <div className="container">
        <div className="row gx-lg-5">
          <div className="col-lg mb-5">
            <div className="d-flex gap-3 gap-lg-4 gap-xl-5 flex-row justify-content-start align-items-start">
              <div>
                <Image src={MDSLogo} width={80} height={80} />
              </div>
              <div className="text-start">
                <h5 className="mds-fw-xl mds-lg-fw-xxl  mds-fs-lg mds-lg-fs-xl mds-text-dark mb-1">
                  Modern Data Stack
                </h5>
                <p className="mds-fw-sm mfds-fs-5 mds-text-secondary m-0">
                  Everything that you need to know to setup and operate a modern data
                  stack
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg mb-5 ">
            <div className="row ms-auto">
              <div className="col">
                <nav className="d-flex flex-column gap-3 mds-fw-md mds-fs-5">
                  <Link href="/community">
                    <a className="mds-text-dark">Community</a>
                  </Link>
                  <Link href="/stacks">
                    <a className="mds-text-dark">Data Stacks</a>
                  </Link>
                  <Link href="/categories">
                    <a className="mds-text-dark">Categories</a>
                  </Link>
                  <Link href="/companies">
                    <a className="mds-text-dark">Companies</a>
                  </Link>
                  {/* <Link href="/resources"><a className="mds-text-dark">Resources</a></Link> */}
                  {/* <Link href="/influencers"><a className="mds-text-dark">Influencers</a></Link> */}
                  <Link href="/people">
                    <a className="mds-text-dark">People</a>
                  </Link>
                  <Link href="/journal">
                    <a className="mds-text-dark">Journal</a>
                  </Link>
                </nav>
              </div>
              <div className="col">
                <nav className="d-flex flex-column align-items-start gap-3 mds-fw-md mds-fs-5">
                  <a
                    href="https://letters.moderndatastack.xyz"
                    target="_blank"
                    rel="noreferrer"
                    className="mds-text-dark"
                  >
                    Newsletter
                  </a>
                  <button
                    onClick={() => setModalShow(true)}
                    className="btn p-0 mds-fw-md mds-fs-5 mds-text-dark link-light mds-cur-pointer"
                  >
                    Contact Us
                  </button>
                  <Link href="/sitemap-html">
                    <a className="mds-text-dark">Sitemap</a>
                  </Link>
                  <div className="mds-text-dark">
                    <div className="mb-2">Follow Us:</div>
                    <div className="d-flex gap-2 justify-content-start">
                      <a
                        onClick={() => trackClick('Twitter')}
                        href="https://twitter.com/moderndatastack"
                        target="_blank"
                        rel="noreferrer"
                        className="text-white mds-bg-primary social-icons"
                      >
                        <BsTwitter />
                      </a>
                      <a
                        onClick={() => trackClick('Linkedin')}
                        href="https://www.linkedin.com/company/moderndatastack"
                        target="_blank"
                        rel="noreferrer"
                        className="text-white mds-bg-primary social-icons"
                      >
                        <GrLinkedinOption />
                      </a>
                      <a
                        onClick={() => trackClick('Reddit')}
                        href="https://www.reddit.com/r/ModernDataStack/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-white mds-bg-primary social-icons"
                      >
                        <FaRedditAlien />
                      </a>
                    </div>
                  </div>
                  <div className="d-none d-lg-block">
                    <p className="m-0 small text-muted mds-fw-xs text-start">
                      Operated By Team At
                      <a
                        className="ms-2 mds-text-primary mds-fw-md"
                        href="https://quantive.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Quantive
                      </a>
                    </p>
                  </div>
                </nav>
              </div>
            </div>
          </div>
          <div className="d-lg-none">
            <p className="m-0 text-muted mds-fw-xs text-center">
              Operated By Team At{' '}
              <a
                className="ms-2 mds-text-primary mds-fw-md"
                href="https://quantive.com/"
                target="_blank"
                rel="noreferrer"
              >
                Quantive
              </a>
            </p>
          </div>
        </div>
      </div>
      <ModalContactUs show={modalShow} onHide={handleHideModal} fullscreen={'lg-down'} />
    </footer>
  );
};

export default Footer;
