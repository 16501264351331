import { AiOutlineClose } from "react-icons/ai";
import { IoMdSearch } from "react-icons/io";
import Spinner from "react-bootstrap/Spinner";
import Image from "next/image";
import { Row, Col } from "react-bootstrap";
import {
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
  useContext,
} from "react";
import MenuImage from "../assets/Illustrations/menu_new_logo.svg";
import { useRouter } from "next/router";
import { createUser } from "../pages/api/user/index";
import Link from "next/link";
import SearchedCategories from "./OmniSearchComponents/searchedCategories";
import SearchedCompanies from "./OmniSearchComponents/searchedCompanies";
import SearchedStacks from "./OmniSearchComponents/searchedStacks";
import { debounce } from "lodash";
import { FaUserCircle } from "react-icons/fa";
import ImageAwardsText from "@/images/AwardsText.svg";
import ImageRocket from "@/images/Rocket.svg";
import PodcastLogo from "@/images/podcast_logo.svg";
import { AppContext } from "@/pages/_app";
import Avatar from "react-avatar";
const algoliasearch = require("algoliasearch");
const client = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APPID,
  process.env.NEXT_PUBLIC_ALGOLIA_APIKEY
);

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const Menu = (props) => {
  const {
    loggedInUser,
    refreshLoggedInUser,
    handleRedirectToLoginPage,
    handleRedirectToSignupPage,
  } = useContext(AppContext);
  const router = useRouter();
  const { pathname } = router;
  const [width] = useWindowSize();
  const [categoriesSearchData, setcategoriesSearchData] = useState([]);
  const [triggerDropdown, settriggerDropdown] = useState(false);
  const [companiesSearchData, setcompaniesSearchData] = useState([]);
  const [stacksSearchData, setstacksSearchData] = useState([]);
  const [searchLoading, setsearchLoading] = useState(false);
  const navOffcanvas = useRef();
  const closeOffcanvasBtn = useRef();
  const searchInput = useRef();

  useEffect(() => {
    if (width > 992) {
      handleCloseOffcanvas();
    }
  }, [width]);

  // searbar

  const [searchFocused, setSearchFocused] = useState(false);

  const [searchText, setSearchText] = useState("");

  const handleSearchText = debounce((e) => {
    e.preventDefault();
    setSearchText(e.target.value);
    handleAlgoliaFetch(e.target.value);
  }, 500);

  const childCallback = (value) => {
    setSearchFocused(false);
    settriggerDropdown(false);
  };

  const handleCloseOffcanvas = () => {
    closeOffcanvasBtn.current.click();
  };

  const handlenavOffcanvasLink = (link) => {
    window.analytics.track(`nav_clicked`, {
      nav: link.replace("/", ""),
      user: loggedInUser ? loggedInUser : null,
    });
    // navOffcanvas.current.classList.remove('show')
    handleCloseOffcanvas();
    router.push(link);
  };
  const handleAlgoliaFetch = (text) => {
    setsearchLoading(true);
    const queries = [
      {
        indexName: "categories",
        query: text,
        params: {
          hitsPerPage: 100,
        },
      },
      {
        indexName: "vendors",
        query: text,
      },
      {
        indexName: "stacks",
        query: text,
      },
    ];

    if (text.length > 0 && text != " ") {
      const data = client
        .multipleQueries(queries)
        .then((res) => {
          setcategoriesSearchData(res.results[0].hits);
          setcompaniesSearchData(res.results[1].hits);
          setstacksSearchData(res.results[2].hits);
          setsearchLoading(false);
          window.analytics.track(`searched_text`, {
            user: loggedInUser ? loggedInUser : null,
            text: text,
            data: {
              categoriesData: res.results[0].hits,
              companiesData: res.results[1].hits,
              stacksData: res.results[2].hits,
            },
          });
        })
        .catch((error) => {
          console.log("error", error);
          setsearchLoading(false);
          window.analytics.track(`searched_text`, {
            user: loggedInUser ? loggedInUser : null,
            text: text,
            data: {
              categoriesData: null,
              companiesData: null,
              stacksData: null,
            },
          });
        });
    }
  };

  const handleSearchFocus = () => {
    settriggerDropdown(true);
    setSearchFocused(true);
  };

  const handleSearchUnFocus = (e) => {
    setSearchFocused(false);
  };

  //for navbar auth links
  const [navLinks, setNavLinks] = useState([
    { name: "Categories", to: "/categories" },
    { name: "Data Stacks", to: "/stacks" },
  ]);
  
  //for navbar auth links
  const [otherLinks, setOtherLinks] = useState([
    // { name: "Resources", to: "/resources" },
    // { name: "Influencers", to: "/influencers" },
    { name: "Companies", to: "/companies" },
    { name: "Compare Companies", to: "/compare" },
    { name: "Newsletter", to: "https://letters.moderndatastack.xyz/" },
    {name:"Community", to:"/community"}
  ]);
  const [securedLinks, setSecuredLinks] = useState([]);

  useEffect(() => {
    if (loggedInUser) {
      setSecuredLinks([
        { name: "Profile", to: "/profile", img: loggedInUser.imageUrl },
        { name: "Logout", to: "/auth/logout" },
      ]);
    } else {
      setSecuredLinks([{ name: "Login", to: "/auth/login" }]);
    }
  }, [loggedInUser]);

  // when clicked outside the searchbox

  const handleSearchTypeClick = (type) => {
    // console.log('it is owkring')
    settriggerDropdown(false);
    router.push(`/search/${type}?q=${searchText}`);
    handleCloseOffcanvas();
  };
  //fetching data from algolia
  const handleSearchOperation = debounce((text) => {
    const queries = [
      {
        indexName: "Category",
        query: text,
        params: {
          hitsPerPage: 100,
        },
      },
      {
        indexName: "Vendor",
        query: text,
      },
      {
        indexName: "Stack",
        query: text,
      },
    ];

    if (text.length > 0 && text != " ") {
      const data = client.multipleQueries(queries).then((res) => {
        setcategoriesSearchData(res.results[0].hits);
        setcompaniesSearchData(res.results[1].hits);
        setstacksSearchData(res.results[2].hits);
      });
    }
    // else {

    //   setcategoriesSearchData([])
    //   setcompaniesSearchData([])
    //   setstacksSearchData([])
    // }
  }, 500);

  const handleClose = (data) => {
    setShow(false);
    window.analytics.track(`nav.${data}.clicked`, loggedInUser);
    props.onSubmit(false);
  };
  const handleShow = () => {
    setShow(true);
    props.onSubmit(true);
  };

  const handleHomePage = () => {
    router.push("/");
  };

  // track segment event
  const trackClick = (nav) => {
    window.analytics.track(`nav_clicked`, {
      nav: nav,
      user: loggedInUser ? loggedInUser : null,
    });
  };

  // clear search text
  const handleClearSeach = () => {
    searchInput.current.value = "";
    handleSearchUnFocus();
    setSearchText("");
    handleAlgoliaFetch("");
  };

  return (
    <nav className="mdsNavbar py-3 navbar navbar-expand-lg mds-bg-primary-muted">
      <div className="container">
        <Link href="/">
          <a>
            {
              pathname.includes("podcast") ?
                <img
                  onClick={() => trackClick("homepage")}
                  className="navbar-brand"
                  src="/podcast_logo.svg"
                  height={50}
                  width={136}
                  alt="Modern Data Stack Logo"
                  style={{ cursor: "pointer" }}
                />
                :
                <img
                  onClick={() => trackClick("homepage")}
                  className="navbar-brand"
                  src="/logo.svg"
                  height={50}
                  width={50}
                  alt="Modern Data Stack Logo"
                  style={{ cursor: "pointer" }}
                />
            }
          </a>
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <Image src={MenuImage} layout="fixed" height={35} width={35} alt="" />
        </button>

        <div
          ref={navOffcanvas}
          className={
            "offcanvas offcanvas-end " + (width < 992 && " mds-mobile-view ")
          }
          tabIndex={-1}
          id="navbarTogglerDemo02"
        >
          <div className="offcanvas-body">
            <div className="container-fluid pe-0 gap-3 gap-lg-3 d-flex  flex-column flex-lg-row align-items-stretch align-items-lg-center justify-content-between">
              <div
                tabIndex={0}
                className="flex-fill dropdown "
                onFocus={handleSearchFocus}
                onBlur={handleSearchUnFocus}
              >
                <div
                  className={
                    "input-group  px-2 border search-group align-items-center " +
                    (width < 992 && " py-1  ") +
                    (searchFocused && " search-group-focused ")
                  }
                >
                  <span className="input-group-text fw-bold bg-transparent border-0">
                    <IoMdSearch />
                  </span>
                  <input
                    ref={searchInput}
                    placeholder="Search stacks, categories, companies"
                    onChange={handleSearchText}
                    type="text"
                    className="search-input form-control border-0"
                  />
                  <span
                    onClick={handleClearSeach}
                    className={
                      (!searchFocused && " d-none ") +
                      "mx-auto btn clear-btn d-flex justify-content-center align-items-center "
                    }
                  >
                    <AiOutlineClose />
                  </span>
                </div>
                <div
                  className={
                    "search-dropdown dropdown-menu w-100 p-0 p-lg-3 " +
                    (searchFocused &&
                      triggerDropdown &&
                      searchText.length > 0 &&
                      " show ")
                  }
                  aria-labelledby="dropdownSearchResult"
                  onClick={(event) => event.stopPropagation()}
                >
                  {searchLoading === true && searchText.length > 0 ? (
                    <Row className="justify-content-center py-3 my-auto h-100">
                      <Col className="d-flex justify-content-center my-auto">
                        <Spinner
                          style={{ color: "#f66640" }}
                          as="span"
                          animation="border"
                          size="lg"
                          role="status"
                          aria-hidden="true"
                        />
                      </Col>
                    </Row>
                  ) : (
                    <div className="mt-3 mt-lg-0">
                      {(categoriesSearchData.length > 0 ||
                        companiesSearchData.length > 0 ||
                        stacksSearchData.length > 0) &&
                      searchText.length > 0 ? (
                        <div className="d-flex flex-column gap-1">
                          {categoriesSearchData.length > 0 && (
                            <div className="search-result-dropdown-card card pb-3 bg-transparent rounded-0 border-0 ">
                              <div className="card-body p-0">
                                <div className="d-grid">
                                  <div
                                    className="btn px-0 py-2 text-start fs-6"
                                    style={{ color: "#f66640" }}
                                    onClick={() =>
                                      handleSearchTypeClick("categories")
                                    }
                                  >
                                    Categories{" "}
                                    <span>
                                      <img
                                        width="12"
                                        height="12"
                                        src="/Illustrations/Greater than logo.svg"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <Row>
                                    {categoriesSearchData
                                      .slice(0, 4)
                                      .map((item) => (
                                        <Col
                                          lg={6}
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          key={item.slug}
                                        >
                                          <SearchedCategories
                                            handleCloseOffcanvas={
                                              handleCloseOffcanvas
                                            }
                                            sendToNavjs={childCallback}
                                            category={item}
                                          />
                                        </Col>
                                      ))}
                                  </Row>
                                </div>
                              </div>
                            </div>
                          )}

                          {companiesSearchData.length > 0 && (
                            <div className="search-result-dropdown-card card pb-3 bg-transparent rounded-0 border-0">
                              <div className="card-body p-0">
                                <div
                                  className="btn px-0 py-2  text-start fs-6 "
                                  style={{ color: "#f66640" }}
                                  onClick={() =>
                                    handleSearchTypeClick("companies")
                                  }
                                >
                                  Companies{" "}
                                  <span>
                                    <img
                                      width="12"
                                      height="12"
                                      src="/Illustrations/Greater than logo.svg"
                                      alt=""
                                    />
                                  </span>
                                </div>
                                <div>
                                  <Row>
                                    {companiesSearchData
                                      .slice(0, 4)
                                      .map((item) => (
                                        <Col
                                          lg={6}
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          key={item.slug}
                                        >
                                          <SearchedCompanies
                                            handleCloseOffcanvas={
                                              handleCloseOffcanvas
                                            }
                                            sendToNavjs={childCallback}
                                            company={item}
                                          />
                                        </Col>
                                      ))}
                                  </Row>
                                </div>
                              </div>
                            </div>
                          )}

                          {stacksSearchData.length > 0 && (
                            <div className="search-result-dropdown-card card pb-3 bg-transparent rounded-0 border-0">
                              <div className="card-body p-0">
                                <div
                                  onClick={() =>
                                    handleSearchTypeClick("stacks")
                                  }
                                  className="btn px-0 py-2  text-start fs-6 "
                                  style={{ color: "#f66640", fontSize: "14px" }}
                                >
                                  Stacks{" "}
                                  <span>
                                    <img
                                      width="12"
                                      height="12"
                                      src="/Illustrations/Greater than logo.svg"
                                      alt=""
                                    />
                                  </span>
                                </div>
                                <Row>
                                  {stacksSearchData
                                    .slice(0, 4)
                                    .map((item, index) => (
                                      <Col
                                        lg={6}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        key={item.slug}
                                      >
                                        <SearchedStacks
                                          index={index}
                                          handleCloseOffcanvas={
                                            handleCloseOffcanvas
                                          }
                                          sendToNavjs={childCallback}
                                          company={item}
                                        />
                                      </Col>
                                    ))}
                                </Row>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <p className="p-0 m-0 mds-text-primary">
                          No result found for{" "}
                          <span className="fw-bold">{`"${searchText}"`}</span>
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={"flex-fill " + (searchFocused && " d-none ")}>
                <div className="mds-navlinks d-flex flex-column flex-lg-row gap-4 gap-lg-1 align-items-strech align-items-lg-center justify-content-between">
                  {width < 992 && (
                    <>
                    <button className="btn d-flex justify-content-between align-items-center mds-summit-nav-btn">
                        <span
                          className=
                            "text-nowrap mds-fw-lg w-100 " 
                          
                          onClick={() => handlenavOffcanvasLink("/summit")}
                        >
                          <div className="d-flex justify-content-between align-items-center gap-2">
                            MDS Summit'23
                            
                          </div>
                        </span>
                      </button>
                      <button className="btn d-flex justify-content-between align-items-center">
                        <span
                          className={
                            "text-nowrap mds-fw-lg w-100 " +
                            (pathname === "/podcast" && " mds-text-primary ")
                          }
                          onClick={() => handlenavOffcanvasLink("/podcast")}
                        >
                          <div className="d-flex justify-content-between align-items-center gap-2">
                            Podcast{" "}
                            <span className="badge mds-bg-primary mds-fw-lg">
                              New
                            </span>
                          </div>
                        </span>
                      </button>
                      <button className="btn d-flex justify-content-between align-items-center">
                        <span
                          className={
                            "text-nowrap mds-fw-lg w-100 " +
                            (pathname === "/community" && " mds-text-primary ")
                          }
                          onClick={() => handlenavOffcanvasLink("/community")}
                        >
                          <div className="d-flex justify-content-between align-items-center gap-2">
                            Community{" "}
                          </div>
                        </span>
                      </button>
                      <button className="btn d-flex justify-content-between align-items-center">
                        <span
                          className={
                            "text-nowrap mds-fw-lg w-100 " +
                            (pathname === "/journal" && " mds-text-primary ")
                          }
                          onClick={() => handlenavOffcanvasLink("/journal")}
                        >
                          <div className="d-flex justify-content-between align-items-center gap-2">
                            Journal{" "}
                          </div>
                        </span>
                      </button>
                      <button className="btn d-flex justify-content-between align-items-center">
                        <span
                          className={
                            "text-nowrap mds-fw-lg w-100 " +
                            (pathname === "/people" && " mds-text-primary ")
                          }
                          onClick={() => handlenavOffcanvasLink("/people")}
                        >
                          <div className="d-flex justify-content-between align-items-center gap-2">
                            People{" "}
                          </div>
                        </span>
                      </button>
                      <button className="btn d-flex justify-content-between align-items-center">
                        <span
                          className={
                            "text-nowrap " +
                            (pathname === "/awards" && " mds-text-primary ")
                          }
                          onClick={() => handlenavOffcanvasLink("/awards")}
                        >
                          Awards
                        </span>
                      </button>
                    </>
                  )}
                  {width < 992 ? (
                    <>
                      {[...navLinks, ...otherLinks].map((link, index) => {
                        return (
                          <button
                            key={index}
                            className="btn d-flex justify-content-between align-items-center"
                          >
                            <span
                              className={
                                "text-nowrap mds-fw-lg " +
                                (link.to === pathname && " mds-text-primary ")
                              }
                              onClick={() => handlenavOffcanvasLink(link.to)}
                            >
                              {link.name}
                            </span>

                            {link.hasOwnProperty("img") && (
                              <span
                                className="d-flex align-items-center"
                                style={{
                                  borderRadius: "100%",
                                  overflow: "hidden",
                                }}
                              >
                                <Image
                                  src={link.img}
                                  height={40}
                                  width={40}
                                  alt=""
                                />
                              </span>
                            )}
                          </button>
                        );
                      })}

                      {loggedInUser ? (
                        <>
                          <button className="btn d-flex justify-content-between align-items-center">
                            <span
                              className="text-nowrap "
                              onClick={() => {
                                handlenavOffcanvasLink("/profile");
                                handleCloseOffcanvas();
                              }}
                            >
                              Profile
                            </span>

                            <span>
                              {loggedInUser?.imageUrl ? (
                                <Image
                                  layout="fixed"
                                  className="rounded-circle"
                                  src={loggedInUser?.imageUrl}
                                  height={40}
                                  width={40}
                                  alt=""
                                />
                              ) : (
                                <Avatar
                                  name={loggedInUser.fullName}
                                  size="40"
                                  round
                                />
                              )}
                            </span>
                          </button>
                          <button className="btn d-flex justify-content-between align-items-center">
                            <span
                              className="text-nowrap "
                              onClick={() => {
                                handlenavOffcanvasLink("/auth/logout");
                                handleCloseOffcanvas();
                              }}
                            >
                              Logout
                            </span>
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => {
                              handleRedirectToLoginPage();
                            }}
                            className="btn text-start text-nowrap"
                          >
                            Login
                          </button>
                          <button
                            onClick={() => {
                              handleRedirectToSignupPage();
                            }}
                            className="btn text-start text-nowrap "
                          >
                            Signup
                          </button>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {/* <Link href="/awards" >
                                            <a className={"btn d-flex align-items-center gap-2 text-nowrap text-start rounded-0 border-0 mds-border-primary " + (pathname === "/awards" && " mds-fw-md mds-text-primary  border-2 border-bottom  ")}>
                                                <Image src={ImageRocket} layout="fixed" width={21} height={20} />
                                                <Image src={ImageAwardsText} layout="fixed" width={60} height={20} alt="awards" />
                                            </a>
                                        </Link> */}

                      <Link href="/summit">
                        <a
                          className={
                            "btn d-flex align-items-center mds-fw-md gap-2 mds-summit-nav-btn text-nowrap text-start rounded-0 border-0 mds-border-primary " +
                            (pathname === "/summit" &&
                              "")
                          }
                        >

                          MDS Summit'23{" "}
        
                        </a>
                      </Link>
                      {navLinks.map((link, index) => {
                        return (
                          <Link key={index} href={link.to}>
                            <a
                              target={link.name === "Newsletter" && "_blank"}
                              onClick={() => trackClick(link.name)}
                            >
                              <button
                                className={
                                  "btn text-nowrap text-start rounded-0 border-0 mds-border-primary " +
                                  (pathname === link.to &&
                                    " mds-fw-md mds-text-primary  border-2 border-bottom  ")
                                }
                              >
                                {link.name}
                              </button>
                            </a>
                          </Link>
                        );
                      })}
                      <Link href="/podcast">
                        <a
                          className={
                            "btn d-flex align-items-center gap-2 text-nowrap text-start rounded-0 border-0 mds-border-primary " +
                            (pathname === "/podcast" &&
                              " mds-fw-md mds-text-primary  border-2 border-bottom  ")
                          }
                        >
                          Podcast{" "}
                          <span className="badge mds-bg-primary mds-fw-md">
                            New
                          </span>
                        </a>
                      </Link>

                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          More
                        </button>
                        <ul className="dropdown-menu more-links-dropdown">
                          <li>
                            <Link href="/awards">
                              <a
                                className={
                                  "btn d-flex align-items-center dropdown-item py-2 gap-2 text-nowrap text-start rounded-0 border-0 mds-border-primary " +
                                  (pathname === "/awards" && " active  ")
                                }
                                onClick={() => trackClick("/awards")}
                              >
                                Awards
                              </a>
                            </Link>
                          </li>

                          {otherLinks.map((link, index) => {
                            return (
                              <li key={index}>
                                <Link href={link.to}>
                                  <a
                                    className={
                                      "dropdown-item py-2 " +
                                      (pathname === link.to && " active ")
                                    }
                                    onClick={() => trackClick(link.name)}
                                  >
                                    {link.name}
                                  </a>
                                </Link>
                              </li>
                            );
                          })}
                          <li>
                            <Link href="/journal">
                              <a
                                className={
                                  "btn justify-content-between d-flex align-items-center dropdown-item py-2 gap-2 text-nowrap text-start rounded-0 border-0 mds-border-primary " +
                                  (pathname === "/journal" &&
                                    " active text-white ")
                                }
                                onClick={() => trackClick("/journal")}
                              >
                                <span>Journal</span>
                              </a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/people">
                              <a
                                className={
                                  "btn justify-content-between d-flex align-items-center dropdown-item py-2 gap-2 text-nowrap text-start rounded-0 border-0 mds-border-primary " +
                                  (pathname === "/people" &&
                                    " active text-white ")
                                }
                                onClick={() => trackClick("/people")}
                              >
                                <span>People</span>
                              </a>
                            </Link>
                          </li>
                        </ul>
                      </div>

                      {loggedInUser ? (
                        <div className="dropdown pe-0">
                          <button
                            type="button"
                            className="btn pe-0"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {loggedInUser?.imageUrl ? (
                              <Image
                                className="rounded-circle"
                                src={loggedInUser.imageUrl}
                                layout="fixed"
                                width={30}
                                height={30}
                              />
                            ) : (
                              <Avatar
                                name={loggedInUser.fullName}
                                round
                                size="30"
                              />
                            )}
                          </button>
                          <ul className="dropdown-menu more-links-dropdown">
                            <li>
                              <Link href="/profile">
                                <a
                                  className={
                                    "dropdown-item py-2 " +
                                    (pathname === "/profile" && " active ")
                                  }
                                >
                                  Profile
                                </a>
                              </Link>
                            </li>
                            <li>
                              <Link href="/auth/logout">
                                <a className="dropdown-item py-2 ">Logout</a>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <div className="d-flex align-items-center pe-0">
                          <button
                            type="button"
                            className="btn mds-text-primary"
                            aria-expanded="false"
                            onClick={handleRedirectToLoginPage}
                          >
                            Login
                          </button>
                          <button
                            type="button"
                            className="btn mds-btn-primary text-white"
                            aria-expanded="false"
                            onClick={handleRedirectToSignupPage}
                          >
                            Signup
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="offcanvas-footer d-block d-lg-none">
            <div className="py-3">
              <button
                ref={closeOffcanvasBtn}
                type="button"
                className="mx-auto btn close-btn d-flex align-items-center"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <AiOutlineClose />
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
