import axios from 'axios';

const jwt = require('jsonwebtoken');
const Cookies = require("js-cookie");



/** validate forms elements and highlight invalid fields with error feedback */
export const validateForm = (form, invalidFields) => {
    const allFormControls = form.querySelectorAll('input, select, textarea, .form-control')
    for (let i = 0; i < allFormControls.length; i++) {
        allFormControls[i].classList.remove('is-invalid')
    }

    invalidFields.map((field) => {
        let feedbackElement = form[field.name].nextElementSibling
        if (feedbackElement) {
            feedbackElement.innerText = field.message
        }
        form[field.name].classList.add('is-invalid')
    })
}


/** get loggedin user details from jwt token stored in cookies */
export const getUserFromCookies = ()=>{
  const userToken = Cookies.get("userToken");
  const loggedInUser = jwt.decode(userToken);
  return loggedInUser;
}


/** common function to call backend api */
export const callBackendApi = async({url, method, data , params , context}) => {
    let userToken;
    if (context) {
        userToken = context.req.cookies['userToken'];
    } else {
        userToken = Cookies.get("userToken");
    }
    return await axios(
        {
            url : process.env.NEXT_PUBLIC_BACKEND_URL + url,
            method,
            data,
            headers : {
                Authorization : userToken || null
            },
            params
        }
    )
}




/** get username from email address */
export const getUsernameFromEmail = ({email}) => {
    return email.substring(0, email.lastIndexOf("@"));
}

/** get regex for the type of input */
export const getRegExp = (type) => {
    let regx = null
    switch (type) {
        case 'URL':
            regx = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/; 
            break;
        case 'EMAIL': 
            regx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            break;
        case 'USERNAME':
            regx = /^[a-zA-Z0-9.\_]{3,30}$/
            break;
        case 'IMAGE_URL': 
            regx = /^http[^\?]*.*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gmi    
            break;
        case 'COMPARE_URL':
            regx = /(?<tool1Slug>.+)-vs-(?<tool2Slug>.+)/
            break;
        default:
            break;
    }

    return regx
}

export const validateImageUrl = async (url) => {
    try {
      if(getRegExp('IMAGE_URL').test(url)){
        const res = await axios.get(url);
        if(res.status === 200){
          return true
        }
      }
      return false
    } catch (error) {
      console.log({error});
      return false
    }
  }

export const emailType = async (email) => {
    const personalMailDomains = [
        "gmail",
        "yahoo",
        "redifmail",
        "hotmail",
        "outlook",
      ];
    const hostnameFromEmail = email
    .substring(email.lastIndexOf('@') + 1, email.lastIndexOf('.'))
    .toLowerCase();

     let res = 'work' 

    if(personalMailDomains.includes(hostnameFromEmail)){
        res = 'personal'
    }
    return res
}

export const emailDomainMatch = (domain, email) => {
    
    const hostnameFromEmail = email
    .substring(email.lastIndexOf('@') + 1, email.lastIndexOf('.'))
    .toLowerCase();

    if(domain && domain.includes(hostnameFromEmail)){
        return true
    }

    return false
}


  
