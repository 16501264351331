import axios from "axios";

const graphcms = (method, query) => {
   return axios({
    url:
      "https://api-eu-central-1.graphcms.com/v2/cko8twfkddpvg01xtfz2z0s0c/master",
    method,
    headers: {
      Authorization:
       "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2MjAxMzY1MTIsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEuZ3JhcGhjbXMuY29tL3YyL2Nrbzh0d2ZrZGRwdmcwMXh0ZnoyejBzMGMvbWFzdGVyIiwiaHR0cHM6Ly9tYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiZmE3NTkwNjgtOTIzMC00OTBlLWFkZDYtZDcyY2I0OTc3NzdhIiwianRpIjoiY2tvYTNnNW9lZmZxeDAxeXowZ2VtOWpqdyJ9.YBkwdhqI1Ve3nWQ5umSByvIKTdYNddSc-wv4mcfUuqUQM51IwhNinXvWKTnCYoSBEwjLwP5_mOZ4_ZGi85agZUPIO8W6o7wxlG8gPIij0R5KOtjyzWfyajupeQyFLpUOesjDYSmxZT77NTOE4hh3TC3A5bJ5yS54nzM68j83VtqhgpzZzljxqBHUzenxovy97NTuXq5JRVvuW3OMjQkjqEiC8nOWUCKCybnkZc_SnsBMtJJIp9kz33lU8rIQMlVA13T-WP3sRd7QtFD6nFW_IykjlT8tnRdhCtFrw-23BB2h88g5Tf5C3KeTh-a_KNHBGc8iwtnqIbj07kR0RUkWHUH-T5O02soshMMh-L2WJXzqc1vP6lZPmK3zM5eOzcKaHE9Q9hKlziaafrG0DjhgHZS67RklD0hvDKaZ04ENk4PkdFFGLXnIXkUFFp7ZyVrO5FLe1MiEimKFG659qUx0m1I131uqAT64Wu2sItFFl34v7jmWnloIKUTJbM0RylGo4vBJuPi5ASot2oy4GskdcFFsdvUfNYMzCOOd28szoQ6mLej2bgPgEaK5tiZnj7R6FW_zkiWMa6y97tH_CNJt1dKvRE7IxsJs_PV4S8ItyB8-AL-E5MnS_bDPI3m-ANVOMgoAL20yQXooGWaTVs-IxM2cwCTgXGDH2gK05xpoY8g",
    },
    data: {
      query: query,
    },
  })
}

export default graphcms