import { useState, useEffect } from "react";
import {Tooltip} from "react-bootstrap";
import { useRouter } from "next/router";
import ImageCompanyLogo from "../ImageCompanyLogo";
import IconVerified from "@/images/verified.png"
import Image from "next/image";

const SearchedStacks = (props) => {
    const {company, index} = props ; 
    const [mouseEnter, setMouseEnter] = useState(false);
    const router = useRouter();

    const renderVerified = (props) => <Tooltip {...props}>Verified</Tooltip>;;

    const handleStackRedirect = (slug) => {
      props.handleCloseOffcanvas()
      props.sendToNavjs(true)
      router.push(`/stacks/${slug}`)
    }
    
    return (
        <div
        // className={[mouseEnter === true && "shadow", 'mt-3', 'p-1'].join(' ')} 
        //   // className="mt-3 p-1"
        //   style={{ border: "1px solid #FDE0D9", borderRadius: "7px", backgroundColor:"#fff7ea" }}
        style={{cursor:"pointer"}}
        className="search-result-item"

          onMouseEnter={(e) => {
            setMouseEnter(true);
          }}
          onMouseLeave={(e) => {
            setMouseEnter(false);
          }}
          onClick={() => handleStackRedirect(company.slug)}
        >
          <div className="d-flex justify-content-start align-items-start gap-3">
          <span><ImageCompanyLogo className="rounded-circle" company={company} width={50} height={50} /></span>

     
        <div>
          <h5 className=" my-0 pb-1 fs-6 d-flex gap-2 align-items-center" >
            <span
            className="text-capitalize"
            id={"stackResult" + index}
            >{company.companyName}</span>
            {company.verified && <span><Image src={IconVerified} width="20" height="20" /></span>}  
          </h5>
          <p className="fs-6 m-0 lh-sm">
            {company.description.length > 50 ?
              (company.description.slice(0, 50) + "...")
              : (company.description)}
          </p>
        </div>
      </div>
        </div>
    )
}

export default SearchedStacks
