import "../styles/scss/global.scss";
import Head from "next/head";
import { useState, useEffect, useLayoutEffect, createContext } from "react";
import Menu from "../components/nav";
import Footer from "../components/footer";
import { UserProvider } from "@auth0/nextjs-auth0";
import Router from "next/router";
import { Row, Col, Popover } from "react-bootstrap";
import Image from "next/image";
import { useRouter } from "next/router";
import { getUserFromCookies } from "../utils/common";
import SSRProvider from 'react-bootstrap/SSRProvider';
import {version} from "../package.json"; 
import axios from "axios";

const delay = 5;

export const AppContext = createContext();

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}


// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);
  const versionsB = versionB.split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a === 0 || a > b || isNaN(b);
  }
  return false;
};

function MyApp({ Component, pageProps }) {

  console.log('version',version);
  
  const router = useRouter();
  const { pathname } = router;
  const [nav, setnav] = useState();
  const [verficationStatus, setverficationStatus] = useState();
  const [ghostSubscriptionStatus, setghostSubscriptionStatus] = useState()
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [width, height] = useWindowSize();
  const [progress, setProgress] = useState(0);
  const [backendURL, setBackendURL] = useState(process.env.NEXT_PUBLIC_BACKEND_URL);
  const [bodyBackgroundColor, setBodyBackgroundColor] = useState("#fff7ea");
  const [bodyBackgroundImage, setBodyBackgroundImage] = useState("none");
  const excludeHeaderFooterPaths = ['onboarding', 'auth', 'embed', "redirect", "sitemap-html"];


  const popoverLoginAlert = (props) => (
    <Popover {...props} className="mds-popover" id="popover-basic">
      <Popover.Body>
        <div className="d-flex justify-content-between align-items-center gap-3 ">
          <span className="d-flex align-items-center"><Image src="/Illustrations/AlertLogo.svg" width={22} height={22} /></span>
          <small className="text-nowrap">
            <span>Please </span>
            <button onClick={handleRedirectToLoginPage} className="btn px-0 btn-sm mds-text-primary mds-fw-md">Login</button>
            <span> !</span>
          </small>
        </div>
      </Popover.Body>
    </Popover>
  );


  const handleRedirectToLoginPage = (link) => {
    const currentUrl = router.asPath
    localStorage.setItem('urlBeforeLogin', currentUrl)
    router.push('/auth/login')
  }


  const handleRedirectToSignupPage = (link) => {
    const currentUrl = router.asPath
    localStorage.setItem('urlBeforeLogin', currentUrl)
    router.push('/auth/signup')
  }


  // get login user details
  const [loggedInUser, setLoggedInUser] = useState(getUserFromCookies());
  const refreshLoggedInUser = () => {
    setLoggedInUser(getUserFromCookies());
  }


  const childCallback = (value) => {
    setghostSubscriptionStatus(value.ghostSubscriptionStatus)
    setverficationStatus(value.verificationStatus)
    setclickedOutside(value.clickedOutside)
    setShow(false)
    let timer1 = setTimeout(() => setShow(true), delay * 1000);
    return () => {
      clearTimeout(timer1);
    };
  }

  useEffect(() => {
    if(loggedInUser?.emailExist === false){
      return router.push("/welcome")
    }
    
    if (loggedInUser?.verified === false) {
      return router.push("/auth/verification")
    }

    if(loggedInUser?.onboarding === false){
      return router.push('/onboarding')
    }


    fetch('/meta.json').then(response => response.json())
    .then(meta => {
      console.log("meta vrsion",meta.version);
      console.log("package version",version);
      const shouldForceRefresh = semverGreaterThan(
        meta.version,
        version
      );


      if(shouldForceRefresh === true) {
        //add hard refrsh logic here
        router.reload(window.location.pathname)
        console.log("shouldForceRefresh",shouldForceRefresh);
      }
    })
  }, [])

  useEffect(() => {
    document.querySelector("body").style.backgroundColor = bodyBackgroundColor || "#fff7ea";
  }, [bodyBackgroundColor])

  useEffect(() => {
    document.querySelector("body").style.backgroundImage = bodyBackgroundImage === "none" ? bodyBackgroundImage : `url(${bodyBackgroundImage})`;
  }, [bodyBackgroundImage])


  const start = () => {
    setLoading(true);
    setProgress(0)
    setProgress(25)
  };

  const end = () => {
    setProgress(100);
    setTimeout(function () {
      setLoading(false);
    }, 400);

  };

  useEffect(() => {
    import("bootstrap/dist/js/bootstrap.bundle");
    Router.events.on("routeChangeStart", start);
    Router.events.on("routeChangeComplete", end);
    Router.events.on("routeChangeError", end);
  }, []);



  function onSubmit(formState) {
    setnav(formState);
  }

  return (
    <SSRProvider>
      <AppContext.Provider
        value={{
          backendURL, loggedInUser, refreshLoggedInUser, handleRedirectToLoginPage, handleRedirectToSignupPage, popoverLoginAlert
          , bodyBackgroundColor, setBodyBackgroundColor, bodyBackgroundImage, setBodyBackgroundImage
        }} >
        <UserProvider>
          <Head>

            <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap" rel="stylesheet" />

            <link rel="search" type="application/opensearchdescription+xml" title="Modern Data Stack" href="/osd.xml" />

            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(
                  {
                    "@context": "https://schema.org",
                    "@type": "WebSite",
                    "url": "https://www.moderndatastack.xyz/",
                    "potentialAction": {
                      "@type": "SearchAction",
                      "target": {
                        "@type": "EntryPoint",
                        "urlTemplate": "https://www.moderndatastack.xyz/search/companies?q={search_term_string}"
                      },
                      "query-input": "required name=search_term_string"
                    }
                  }
                )
              }}
            />


          </Head>

          {!show && (
            <>
              {ghostSubscriptionStatus === true && (
                <div style={{ backgroundColor: "#f66640" }} className="">
                  <Row className="justify-content-center">
                    <Col className="d-flex justify-content-center">
                      <p style={{ color: "white" }} className="m-2"> <sapn style={{ marginRight: "10px" }}><img width="25" height="25" src="/Illustrations/tick.svg" /></sapn>Successfully subscribed to our newsletter</p>
                    </Col>
                  </Row>
                </div>
              )}

              {verficationStatus === true && (
                <div style={{ backgroundColor: "#f66640" }} className="">
                  <Row className="justify-content-center">
                    <Col className="d-flex justify-content-center">
                      <p style={{ color: "white" }} className="m-2"> <sapn style={{ marginRight: "10px" }}><img width="25" height="25" src="/Illustrations/tick.svg" /></sapn>Your e-mail has been verified!</p>
                    </Col>
                  </Row>
                </div>
              )}

            </>

          )}


          {/* Loading Bar Section */}
          <div className="progress fixed-top bg-transparent" style={{ height: "3px" }}>
            <div
              role="progressbar"
              className={"progress-bar shadow-sm mds-bg-primary " + (!loading ? " d-none " : "")}
              style={{ width: `${progress}%`, transition: (loading && "width 1s")}}></div>
          </div>
          <div className="d-flex flex-column justify-content-start" style={{minHeight : "100vh"}}>
          {/* Header Section */}
          {excludeHeaderFooterPaths.some(item => pathname.includes(item)) || <Menu onSubmit={onSubmit} nav={nav} loading={loading} sendToAppjs={childCallback} />}
          {/* Main Page Component Section */}
          <Component {...pageProps} nav={nav} loading={loading} sendToAppjs={childCallback} width={width} />
          {/* Footer Section */}
          {excludeHeaderFooterPaths.some(item => pathname.includes(item)) || <Footer />}
          </div>
        </UserProvider>
      </AppContext.Provider>
    </SSRProvider>
  );
}



export default MyApp;
