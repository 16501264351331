import Link from "next/link";
import styles from "../../styles/Home.module.css";
import { Container, Carousel, Row, Col, Button } from "react-bootstrap";
import { useState, useLayoutEffect, useEffect } from "react";
import { useRouter } from "next/router";


const SearchedCompanies = (props) => {
  const { company } = props;
  const [mouseEnter, setMouseEnter] = useState(false);
  const router = useRouter();

  const handleStackRedirect = (slug) => {
    props.handleCloseOffcanvas()
    props.sendToNavjs(true)
    router.push(`/company/${slug}`)
  }

  return (
    <div
      // className={[mouseEnter === true && "shadow", 'mt-3', 'p-1'].join(' ')} 
      //   // className="mt-3 p-1"
      //   style={{ border: "1px solid #FDE0D9", borderRadius: "7px", backgroundColor:"#fff7ea" }}
      onClick={() => handleStackRedirect(company.slug)}
      style={{ cursor: "pointer" }}
      className="search-result-item"
      onMouseEnter={(e) => {
        setMouseEnter(true);
      }}
      onMouseLeave={(e) => {
        setMouseEnter(false);
      }}
    >

      <div className="d-flex justify-content-start align-items-start gap-3">
      {company.companyLogo != "null" &&
              company.companyLogo != null ? (
              <img
                // className="m-2"
                width="50"
                height="50"
                src={company.companyLogo}
                alt=""
                style={{ borderRadius: "50%" }}
              />
            ) : company.url.includes("ibm") &&
              company.companyLogo === "null" &&
              company.companyLogo === null ? (
              <img
                // className="m-2"
                width="50"
                height="50"
                src="https://logo.clearbit.com/ibm.com"
                alt=""
                style={{ borderRadius: "50%" }}
              />
            ) : company.url.includes("google") &&
              company.companyLogo === "null" &&
              company.companyLogo === null ? (
              <img
                // className="m-2"
                width="50"
                height="50"
                src="https://logo.clearbit.com/google.com"
                alt=""
                style={{ borderRadius: "50%" }}
              />
            ) : company.url.includes("github") &&
              company.companyLogo === "null" &&
              company.companyLogo === null ? (
              <img
                // className="m-2"
                width="50"
                height="50"
                src="https://logo.clearbit.com/github.com"
                alt=""
                style={{ borderRadius: "50%" }}
              />
            ) : (
              <img
                // className="m-2"
                width="50"
                height="50"
                src={`https://logo.clearbit.com/${company.url}`}
                alt=""
                style={{ borderRadius: "50%" }}
              />
            )}
        <div>
          <h5 className=" my-0 pb-1 fs-6 text-capitalize" >{company.companyName}</h5>
          <p className="fs-6 m-0 lh-sm">
            {company.shortDescription.length > 50 ?
              (company.shortDescription.slice(0, 50) + "...")
              : (company.shortDescription)}
          </p>
        </div>
      </div>
      {/* <Link href="/company/[id]" as={`/company/${company.slug}`}>
            <a className={styles.card} > */}
     
      {/* </a>
          </Link> */}
    </div>
  )
}

export default SearchedCompanies
