import axios from 'axios';
import Image from 'next/image';
import {useEffect, useState} from 'react';


export default function ImageCompanyLogo ({ company, className, style, width, height }) {
    const {companyLogo, url, companyName} = company;
    const [imgUrl, setImgUrl] = useState(null);
    useEffect(async ()=>{
        setImgUrl(getCompanyLogoUrl({companyLogo, url}));
    }, [company])
    return (
        <>
        {companyLogo && companyLogo != "null" ?
         <img 
         className={className + " border mds-border-primary-light bg-white "}
                    width={width || 70}
                    height={height || 70}
                    
                    src={companyLogo}
                    alt={`${companyName} logo`}
                    style={ {...style }}
         /> 
        : imgUrl && 
        <Image
                    layout="fixed"
                    className={className + " border mds-border-primary-light bg-white "}
                    width={width || 70}
                    height={height || 70}
                    src={imgUrl}
                    alt={`${companyName} logo`}
                    style={ {...style }}
                />}
            
        </>
    )
}


export const getCompanyLogoUrl = ({url})=> {
    const brandName = url && url != "null" && ['ibm', 'google', 'github'].find((name)=> {
        return url.includes(name);
    })
    if (brandName) {
        return "https://logo.clearbit.com/" + brandName + ".com";
    } else {
        return `https://logo.clearbit.com/google.com/${url}`;
    }
}

