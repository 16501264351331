import Link from "next/link";
import styles from "../../styles/Home.module.css";
import { Container, Carousel, Row, Col, Button } from "react-bootstrap";
import { useState, useLayoutEffect, useEffect } from "react";
import { useRouter } from "next/router";

const SearchedCategories = (props) => {
  const { category } = props;
  const [mouseEnter, setMouseEnter] = useState(false);
  const router = useRouter();


  const handleStackRedirect = (slug) => {
    props.handleCloseOffcanvas()
    props.sendToNavjs(true)
    router.push(`/category/${slug}`)
  }

  return (
    <div
      // className={mouseEnter === true && "shadow"} 
      //   // className="mt-3 p-1"
      // style={{ border: "1px solid #FDE0D9", borderRadius: "7px", backgroundColor:"#fff7ea" }}
      style={{ cursor: "pointer" }}
      className="search-result-item"
      onClick={() => handleStackRedirect(category.slug)}
      onMouseEnter={(e) => {
        setMouseEnter(true);
      }}
      onMouseLeave={(e) => {
        setMouseEnter(false);
      }}
    >
      {/* <Link href="/category/[id]" as={`/category/${category.slug}`}>*/}
      <div className="d-flex justify-content-start align-items-start gap-3">
        <img src={category.iconUrl} width="45" height="50"></img>
        <div>
          <h5 className=" my-0 pb-1 fs-6" >{category.name}</h5>
          <p className="fs-6 m-0 lh-sm">
            {category.shortDescription.length > 50 ?
              (category.shortDescription.slice(0, 50) + "...")
              : (category.shortDescription)}
          </p>
        </div>
      </div>
      {/* </Link> */}
    </div>
  )
}

export default SearchedCategories
