import graphcmsRequest from "../graphcms";

import axios from "axios";
const createUser = (user) => {
  let userId, authType, userEmail;
  if (user) {
    userId = user.sub.split("|")[1];
    authType = user.sub.split("|")[0];
    userEmail = user.email;
  }
  if (!user) {
    return;
  }

  const query = `
     query MyQuery {
         userDetails(where: { userId: "${userId}", authType: "${authType}"}) {
           id
         }
       }
       `;
  graphcmsRequest("post", query)
    .then((response) => {
      const userExists = response.data.data.userDetails.length > 0;
      if (!userExists) {
        const verificationToken = Math.floor(100000 + Math.random() * 900000);

        const createUserMutation = `
                mutation {
                  createUserDetail(data: { userId: "${userId}", authType: "${authType}", name: "${user.name}", email:"${user.email}"} ) {
                    id
                  }
                }
            `;

        graphcmsRequest("post", createUserMutation).then((res) => {
          // send MDS (platform) verification email for Linkedin and google-oAuth users.
          // verification link for twitter users will be send from /welcome page because we dont have their(twitter user) email in this stage .

          if (authType != "twitter") {
            let data = {
              userEmail,
              userId,
              verificationToken,
            };

            var updateUserEmail = axios({
              url: "https://api-eu-central-1.graphcms.com/v2/cko8twfkddpvg01xtfz2z0s0c/master",
              method: "post",
              headers: {
                Authorization:
                  "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2MjAxMzY1MTIsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEuZ3JhcGhjbXMuY29tL3YyL2Nrbzh0d2ZrZGRwdmcwMXh0ZnoyejBzMGMvbWFzdGVyIiwiaHR0cHM6Ly9tYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiZmE3NTkwNjgtOTIzMC00OTBlLWFkZDYtZDcyY2I0OTc3NzdhIiwianRpIjoiY2tvYTNnNW9lZmZxeDAxeXowZ2VtOWpqdyJ9.YBkwdhqI1Ve3nWQ5umSByvIKTdYNddSc-wv4mcfUuqUQM51IwhNinXvWKTnCYoSBEwjLwP5_mOZ4_ZGi85agZUPIO8W6o7wxlG8gPIij0R5KOtjyzWfyajupeQyFLpUOesjDYSmxZT77NTOE4hh3TC3A5bJ5yS54nzM68j83VtqhgpzZzljxqBHUzenxovy97NTuXq5JRVvuW3OMjQkjqEiC8nOWUCKCybnkZc_SnsBMtJJIp9kz33lU8rIQMlVA13T-WP3sRd7QtFD6nFW_IykjlT8tnRdhCtFrw-23BB2h88g5Tf5C3KeTh-a_KNHBGc8iwtnqIbj07kR0RUkWHUH-T5O02soshMMh-L2WJXzqc1vP6lZPmK3zM5eOzcKaHE9Q9hKlziaafrG0DjhgHZS67RklD0hvDKaZ04ENk4PkdFFGLXnIXkUFFp7ZyVrO5FLe1MiEimKFG659qUx0m1I131uqAT64Wu2sItFFl34v7jmWnloIKUTJbM0RylGo4vBJuPi5ASot2oy4GskdcFFsdvUfNYMzCOOd28szoQ6mLej2bgPgEaK5tiZnj7R6FW_zkiWMa6y97tH_CNJt1dKvRE7IxsJs_PV4S8ItyB8-AL-E5MnS_bDPI3m-ANVOMgoAL20yQXooGWaTVs-IxM2cwCTgXGDH2gK05xpoY8g",
              },
              data: {
                query: `
                  mutation MyMutation {
                __typename
                updateUserDetail(data: {verificationCode: "${verificationToken}", emailVerified: false}, where: {userId: "${userId}"}) {
                  id
                }
      }
                  `,
              },
            }).then((res) => {
              fetch("/api/sendEmail", {
                method: "POST",
                headers: {
                  Accept: "application/json, text/plain, */*",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              });
            });
          } else {
            var updateUserEmail = axios({
              url: "https://api-eu-central-1.graphcms.com/v2/cko8twfkddpvg01xtfz2z0s0c/master",
              method: "post",
              headers: {
                Authorization:
                  "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2MjAxMzY1MTIsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEuZ3JhcGhjbXMuY29tL3YyL2Nrbzh0d2ZrZGRwdmcwMXh0ZnoyejBzMGMvbWFzdGVyIiwiaHR0cHM6Ly9tYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiZmE3NTkwNjgtOTIzMC00OTBlLWFkZDYtZDcyY2I0OTc3NzdhIiwianRpIjoiY2tvYTNnNW9lZmZxeDAxeXowZ2VtOWpqdyJ9.YBkwdhqI1Ve3nWQ5umSByvIKTdYNddSc-wv4mcfUuqUQM51IwhNinXvWKTnCYoSBEwjLwP5_mOZ4_ZGi85agZUPIO8W6o7wxlG8gPIij0R5KOtjyzWfyajupeQyFLpUOesjDYSmxZT77NTOE4hh3TC3A5bJ5yS54nzM68j83VtqhgpzZzljxqBHUzenxovy97NTuXq5JRVvuW3OMjQkjqEiC8nOWUCKCybnkZc_SnsBMtJJIp9kz33lU8rIQMlVA13T-WP3sRd7QtFD6nFW_IykjlT8tnRdhCtFrw-23BB2h88g5Tf5C3KeTh-a_KNHBGc8iwtnqIbj07kR0RUkWHUH-T5O02soshMMh-L2WJXzqc1vP6lZPmK3zM5eOzcKaHE9Q9hKlziaafrG0DjhgHZS67RklD0hvDKaZ04ENk4PkdFFGLXnIXkUFFp7ZyVrO5FLe1MiEimKFG659qUx0m1I131uqAT64Wu2sItFFl34v7jmWnloIKUTJbM0RylGo4vBJuPi5ASot2oy4GskdcFFsdvUfNYMzCOOd28szoQ6mLej2bgPgEaK5tiZnj7R6FW_zkiWMa6y97tH_CNJt1dKvRE7IxsJs_PV4S8ItyB8-AL-E5MnS_bDPI3m-ANVOMgoAL20yQXooGWaTVs-IxM2cwCTgXGDH2gK05xpoY8g",
              },
              data: {
                query: `
                  mutation MyMutation {
                __typename
                updateUserDetail(data: {emailVerified: false}, where: {userId: "${userId}"}) {
                  id
                }
      }
                  `,
              },
            });
          }
        });
      }
    })
    .catch((e) => console.log("e", e.message));
};

const getUser = async (user) => {
  try {
    let res = await axios.get(`/api/auth/me`);
    return res.data;
  } catch (error) {}
};

const userCompaniesData = async (user) => {
  var companyData;
  var likesData;
  var companies = [];
  let userId, authType;
  if (user) {
    userId = user.sub.split("|")[1];
    authType = user.sub.split("|")[0];
  }
  if (!user) {
    return;
  }

  const userStacksQuery = `
query MyQuery {
  __typename
  stacks(where: {members: "${userId}"}) {
    id
    vendors {
      companyName
      slug
      companyLogo
      url
       categories {
        name
        slug
      }
    }
    stages
    slug
    members
    linkedinProfile
    instagramProfile
    twitterProfile
    url
    companyName
    companyLogo
    description
  }
}`;

  const likedCompanyQuery = `
                  query MyQuery {
                  __typename
                  likes(where: {userId: "${userId}"}) {
                    id
                    vendorId
                  }
                }
                `;

  const userStacks = await graphcmsRequest("post", userStacksQuery);
  const likesRef = await graphcmsRequest("post", likedCompanyQuery);

  if (likesRef.data.data.likes.length > 0) {
    const companyIds = likesRef.data.data.likes.map(
      (company) => company.vendorId
    );
    const companiesRef = await axios({
      url: "https://api-eu-central-1.graphcms.com/v2/cko8twfkddpvg01xtfz2z0s0c/master",
      method: "post",
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2MjAxMzY1MTIsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEuZ3JhcGhjbXMuY29tL3YyL2Nrbzh0d2ZrZGRwdmcwMXh0ZnoyejBzMGMvbWFzdGVyIiwiaHR0cHM6Ly9tYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiZmE3NTkwNjgtOTIzMC00OTBlLWFkZDYtZDcyY2I0OTc3NzdhIiwianRpIjoiY2tvYTNnNW9lZmZxeDAxeXowZ2VtOWpqdyJ9.YBkwdhqI1Ve3nWQ5umSByvIKTdYNddSc-wv4mcfUuqUQM51IwhNinXvWKTnCYoSBEwjLwP5_mOZ4_ZGi85agZUPIO8W6o7wxlG8gPIij0R5KOtjyzWfyajupeQyFLpUOesjDYSmxZT77NTOE4hh3TC3A5bJ5yS54nzM68j83VtqhgpzZzljxqBHUzenxovy97NTuXq5JRVvuW3OMjQkjqEiC8nOWUCKCybnkZc_SnsBMtJJIp9kz33lU8rIQMlVA13T-WP3sRd7QtFD6nFW_IykjlT8tnRdhCtFrw-23BB2h88g5Tf5C3KeTh-a_KNHBGc8iwtnqIbj07kR0RUkWHUH-T5O02soshMMh-L2WJXzqc1vP6lZPmK3zM5eOzcKaHE9Q9hKlziaafrG0DjhgHZS67RklD0hvDKaZ04ENk4PkdFFGLXnIXkUFFp7ZyVrO5FLe1MiEimKFG659qUx0m1I131uqAT64Wu2sItFFl34v7jmWnloIKUTJbM0RylGo4vBJuPi5ASot2oy4GskdcFFsdvUfNYMzCOOd28szoQ6mLej2bgPgEaK5tiZnj7R6FW_zkiWMa6y97tH_CNJt1dKvRE7IxsJs_PV4S8ItyB8-AL-E5MnS_bDPI3m-ANVOMgoAL20yQXooGWaTVs-IxM2cwCTgXGDH2gK05xpoY8g",
      },
      data: {
        query: `
                  query MyQuery2($companyIds:[String!]) {
                  __typename
                  vendors(where: {slug_in: $companyIds}) {
                    shortDescription
            companyName
            companyLogo
            slug
            url
            rating
                    createdAt
                                  }
                }
                `,
        variables: {
          companyIds: companyIds,
        },
      },
    });

    companies = companiesRef.data.data.vendors;
    let companiesLikes = {};
    if (companiesRef.data.data.vendors.length > 0) {
      companiesLikes = await axios({
        url: "https://api-eu-central-1.graphcms.com/v2/cko8twfkddpvg01xtfz2z0s0c/master",
        method: "post",
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2MjAxMzY1MTIsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEuZ3JhcGhjbXMuY29tL3YyL2Nrbzh0d2ZrZGRwdmcwMXh0ZnoyejBzMGMvbWFzdGVyIiwiaHR0cHM6Ly9tYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiZmE3NTkwNjgtOTIzMC00OTBlLWFkZDYtZDcyY2I0OTc3NzdhIiwianRpIjoiY2tvYTNnNW9lZmZxeDAxeXowZ2VtOWpqdyJ9.YBkwdhqI1Ve3nWQ5umSByvIKTdYNddSc-wv4mcfUuqUQM51IwhNinXvWKTnCYoSBEwjLwP5_mOZ4_ZGi85agZUPIO8W6o7wxlG8gPIij0R5KOtjyzWfyajupeQyFLpUOesjDYSmxZT77NTOE4hh3TC3A5bJ5yS54nzM68j83VtqhgpzZzljxqBHUzenxovy97NTuXq5JRVvuW3OMjQkjqEiC8nOWUCKCybnkZc_SnsBMtJJIp9kz33lU8rIQMlVA13T-WP3sRd7QtFD6nFW_IykjlT8tnRdhCtFrw-23BB2h88g5Tf5C3KeTh-a_KNHBGc8iwtnqIbj07kR0RUkWHUH-T5O02soshMMh-L2WJXzqc1vP6lZPmK3zM5eOzcKaHE9Q9hKlziaafrG0DjhgHZS67RklD0hvDKaZ04ENk4PkdFFGLXnIXkUFFp7ZyVrO5FLe1MiEimKFG659qUx0m1I131uqAT64Wu2sItFFl34v7jmWnloIKUTJbM0RylGo4vBJuPi5ASot2oy4GskdcFFsdvUfNYMzCOOd28szoQ6mLej2bgPgEaK5tiZnj7R6FW_zkiWMa6y97tH_CNJt1dKvRE7IxsJs_PV4S8ItyB8-AL-E5MnS_bDPI3m-ANVOMgoAL20yQXooGWaTVs-IxM2cwCTgXGDH2gK05xpoY8g",
        },
        data: {
          query: `
                      query MyQuery($companyIds:[String!]) {
                      likes(where: {vendorId_in: $companyIds}) {
                        userId
                        vendorId
                        createdAt
                      }
                    }
                    `,
          variables: {
            companyIds: companyIds,
          },
        },
      });
    }

    // if(companiesLikes && companiesLikes.data && companiesLikes.data.data.likes) {
    //   console.log("companiesLikes.data.data.likes",companiesLikes.data.data.likes);
    // }

    likesData = companiesLikes.data.data.likes;
    const reduceCompanyLikes = companiesLikes.data
      ? companiesLikes.data.data.likes.reduce((acc, curr) => {
          acc[curr.vendorId] = acc[curr.vendorId] ? acc[curr.vendorId] + 1 : 1;
          return acc;
        }, {})
      : {};

    companies = companiesRef.data.data.vendors.map((item) => ({
      ...item,
      likes: reduceCompanyLikes[item.slug] || 0,
    }));
  }

  return {
    companyData: companies,
    likesData: likesData,
    userStacks: userStacks.data.data.stacks,
  };
};

const setGhostSubscriber = async (userName, userEmail) => {

  let data = {
    userEmail,
    userName,
  };

  try {
    await fetch("/api/ghostSubscription", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  } catch (error) {
    console.log("error during ghost subscription", error);
  }
};

export { createUser, userCompaniesData, getUser, setGhostSubscriber };
